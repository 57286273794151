<template>
  <div class="auth-wrapper">
    <div class="container">
      <div class="row auth-container">
        <div class="col-lg-6 col-md-12 auth-container-left">
          <img
            src="../../assets/images/logo/logo_register.svg"
            class="img-fluid"
          />
        </div>
        <div class="col-lg-6 col-md-12 auth-container-right">
          <div class="auth-card">
            <div class="card-body">
              <span class="auth-card-title">
                {{ $t("auth.new_password") }}
              </span>
              <notification-container />
              <be-form-error v-if="error" :message="error" />
              <form class="form" @submit.prevent="handleNewPasswordForm">
                <BaseInputPassword
                  leftIconClass="icon-lock"
                  v-model="password"
                  :label="$t('auth.new_password')"
                  :placeholder="$t('auth.password')"
                  :isInvalid="
                    $v.password.$error || errors.password !== undefined
                  "
                  @blur="$v.password.$touch()"
                  :errorMessage="passErrMsg"
                >
                </BaseInputPassword>
                <BaseInputPassword
                  leftIconClass="icon-lock"
                  v-model="password_confirmation"
                  :label="$t('auth.confirm_password')"
                  :placeholder="$t('auth.confirmation')"
                  :isInvalid="$v.password_confirmation.$error"
                  @blur="$v.password_confirmation.$touch()"
                  :errorMessage="confirmPassErrMsg"
                >
                </BaseInputPassword>
                <BaseButton
                  :disabled="disabledSubmitBtn"
                  button-class="btn-block btn-secondary font-weight-bold reset-password-base-btn"
                  type="submit"
                  :loading="loading"
                  :showLoading="true"
                >
                  {{ $t("auth.confirm") }}
                </BaseButton>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/layouts/Footer";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { recaptchaMixin } from "../../mixins/recaptchaMixin";
import NotificationContainer from "../../components/notification/NotificationContainer";
import BeFormError from "../../components/common/BeFormError";

export default {
  name: "PasswordResetConfirm",
  mixins: [recaptchaMixin],
  components: { NotificationContainer, Footer, BeFormError },

  data() {
    return {
      loading: false,
      password: null,
      password_confirmation: null,
      error: null,
      errors: [],
    };
  },

  computed: {
    disabledSubmitBtn() {
      return this.$v.$invalid || this.loading || this.recaptchaToken === null;
    },
    passErrMsg() {
      if (this.$v.password.$error || this.errors.password) {
        if (!this.$v.password.required) {
          return this.$t("auth.errors.password.required");
        }
        if (!this.$v.password.minLength) {
          return this.$t("auth.errors.password.min_length");
        }
        if (!this.$v.password.containsUppercase) {
          return this.$t("auth.errors.password.contains_uppercase");
        }
        if (!this.$v.password.containsNumber) {
          return this.$t("auth.errors.password.contains_number");
        }
        if (this.errors.password) {
          return this.errors.password[0];
        }
      }
      return "";
    },
    confirmPassErrMsg() {
      if (this.$v.password_confirmation.$error) {
        if (!this.$v.password_confirmation.required) {
          return this.$t("auth.errors.password_confirmation.required");
        }
        if (!this.$v.password_confirmation.sameAsPassword) {
          return this.$t("auth.errors.password_confirmation.same_as_password");
        }
      }
      return "";
    },
  },

  methods: {
    handleNewPasswordForm() {
      if (!this.$v.invalid) {
        this.loading = true;
        this.$store
          .dispatch("auth/resetPassword", {
            token: this.$route.params.token,
            recaptcha: this.recaptchaToken,
            password: this.password,
            email: this.$route.query.email,
          })
          .then(() => {
            this.$router.push({ name: "Login", query: this.$route.query });
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
            this.loadRecaptcha();
          });
      }
    },
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
      containsUppercase: function(value) {
        return /[A-Z]/.test(value) === true;
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value) === true;
      },
    },
    password_confirmation: { required, sameAsPassword: sameAs("password") },
  },
};
</script>

<style scoped></style>
